import { FormLineProps } from "@common/components";

type onFieldChange = (name: "city" | "zip" | "name", value: string) => void;

export const getSearchStudioFormFields = ({
  labelClassName,
  formLineClassName,
  onFieldChange,
}: {
  labelClassName: string;
  formLineClassName: string;
  onFieldChange: onFieldChange;
}): FormLineProps[] => [
  {
    labelOptions: { title: "NAME", className: labelClassName },
    inputOptions: {
      name: "name",
      "aria-label": "Input field for studio name",
      onChange: (e: any) => onFieldChange("name", e.currentTarget.value),
    },
    className: formLineClassName,
  },
  {
    labelOptions: { title: "ZIP", className: labelClassName },
    inputOptions: {
      name: "zip",
      "aria-label": "Input field for zip number (not required)",
      onChange: (e: any) => onFieldChange("zip", e.currentTarget.value),
    },
    className: formLineClassName,
  },
  {
    labelOptions: { title: "CITY", className: labelClassName },
    inputOptions: {
      name: "city",
      "aria-label": "Input field for city (not required)",
      onChange: (e: any) => onFieldChange("city", e.currentTarget.value),
    },
    className: formLineClassName,
  },
];
