import { EnvConfig } from "@common/configs/EnvConfig";
import { get } from "@common/networking";
import { castStudioToStudioEntity } from "../utils/castStudioToStudioEntity";
import { IStudioRequest, IStudioResponse } from "../interfaces";

export const fetchStudios = async (params: IStudioRequest) => {
  try {
    const studios = await get<IStudioResponse>(`${EnvConfig.ApiUrl}/studios`, {
      ...(params as Record<string, string>),
    });

    return studios.data.map((studio) => castStudioToStudioEntity(studio));
  } catch (e) {
    console.error(e);

    throw e;
  }
};
