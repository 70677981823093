import { StudiosMapMark } from "@studios/interfaces";
import { StudioGeoJSONPoint } from "@studios/types";

export const castMarkersToPoints = async (
  markers: StudiosMapMark[]
): Promise<StudioGeoJSONPoint[]> =>
  await markers.map((marker) => ({
    type: "Feature",
    properties: {
      cluster: false,
      id: marker.id,
      lat: marker.lat,
      lng: marker.lng,
    },
    geometry: {
      type: "Point",
      coordinates: [marker.lng, marker.lat],
    },
  }));
