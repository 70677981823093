import { Flex } from "@common/components";
import styles from "./StudioCredentials.module.scss";
import type { FC } from "react";

export interface IStudioCredentialsProps {
  name: string | null;
}

export const StudioCredentials: FC<IStudioCredentialsProps> = ({ name }) => {
  return (
    <Flex direction="column">
      <h2 className={styles.root__name}>{name ?? 'Studio'}</h2>
      <h3 className={styles.root__info}>IBBFA Approved Studio</h3>
    </Flex>
  );
};
