import { concatClassNames } from "@common/utils";
import styles from "./StudioAddress.module.scss";

export type StudioAddressProps = {
  address: string | null;
  phone: string | null;
  email: string | null;
  website?: string | null;
};

export const StudioContacts: React.FC<StudioAddressProps> = ({
  address,
  phone,
  email,
  website,
}) => {
  const ITEMS = [
    {
      placeholder: "PHONE: ",
      externalValue: phone,
    },
    {
      placeholder: "EMAIL: ",
      externalValue: email,
    },
    {
      placeholder: "ADDRESS: ",
      externalValue: address,
    },
  ];

  return (
    <ul className={styles.root}>
      {ITEMS.map(
        ({ placeholder, externalValue }) =>
          externalValue && (
            <li key={placeholder} className={styles.item}>
              <span>{placeholder}</span>
              <span>{externalValue}</span>
            </li>
          )
      )}
      {website && (
        <li className={styles.item}>
          <span>WEBSITE:</span>
          <a href={website} rel="nofollow" target="_blank" className={styles.item__link}>
            {website}
          </a>
        </li>
      )}
    </ul>
  );
};
