import { IStudio } from "../interfaces";

export const castStudioToStudioEntity = (studio: IStudio): IStudio => ({
  id: studio ? studio.id : null,
  address: studio ? studio.address : null,
  name: studio ? studio.name : null,
  logo: studio ? studio.logo : null,
  phone: studio ? studio.phone : null,
  email: studio ? studio.email : null,
  desc: studio ? studio.desc : null,
  website: studio ? studio.website : null,
  instructor: studio ? studio.instructor : null,
  instructor_info: studio ? studio.instructor_info : null,
  lat: studio ? studio.lat : null,
  lng: studio ? studio.lng : null,
});
