import { Loader } from "@common/components/atoms/Loader";
import { fetchInstructors, fetchMapMarks } from "@instructors/network";
import { InstructorEntity, InstructorMapMark } from "@instructors/types";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import styles from "./Instructor.module.scss";
import type { ResolvingMetadata, Metadata } from "next";
import Head from "next/head";
import { metadata } from "@common/constants";
import { StudioCard } from "@studios/components";
import Image from "next/image";
import { InstructorLayout, InstructorPageCard } from "@instructors/components";

type Props = {
  params: { instructor: string };
  searchParams: { [key: string]: string | string[] | undefined };
};

export async function generateMetadata(
  { params }: Props,
  parent: ResolvingMetadata
): Promise<Metadata> {
  const instructor = params.instructor;

  const currentPathname = window.location.href;
  const instructorData = await fetch(`${currentPathname}/${instructor}`).then(
    (res) => res.json()
  );

  const previousImages = (await parent).openGraph?.images || [];

  return {
    title: instructorData.title,
    openGraph: {
      images: ["/some-specific-page-image.jpg", ...previousImages],
    },
  };
}

interface InstructorProps {
  mapMarks: InstructorMapMark[];
}

export default function Instructor({ mapMarks }: InstructorProps) {
  const pathname = usePathname();
  const [isLoading, setIsLoading] = useState(true);
  const [instructor, setInstructor] = useState<InstructorEntity | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const pathSegments = pathname ? pathname.split("/") : [];
      if (pathSegments.length > 1) {
        const id = pathSegments[1];
        const instructorData = await fetchInstructors({
          certificate_number: id,
          enabled: "0",
          limit: "100",
        });

        if (instructorData && instructorData.length > 0) {
          setInstructor(instructorData[0]);
        }
        setIsLoading(false);
      }
    };

    fetchData();
  }, [pathname]);

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }
  if (!instructor) {
    return (
      <Image
        src="/img/404.jpg"
        alt="Not found"
        width={450}
        height={450}
        className="notFound"
      />
    );
  }

  return (
    <>
      <Head>
        <title>
          {instructor?.isActive ? instructor?.fullName : "Certificate Expired"}
        </title>
        <meta
          name="description"
          content={
            instructor?.isActive
              ? instructor?.description
                ? instructor.description
                : ""
              : "Certificate Expired"
          }
        />
        <meta
          property="og:title"
          content={
            instructor?.isActive
              ? instructor.fullName
              : (metadata.openGraph?.title as string)
          }
        />
        <meta
          property="og:description"
          content={
            instructor?.isActive
              ? instructor?.description
                ? instructor.description
                : (metadata.description as string)
              : "Certificate Expired"
          }
        />
        <meta
          property="og:url"
          content={`${metadata.openGraph?.url}/${pathname.split("/")[1]}`}
        />
      </Head>

      <InstructorLayout mapMarks={mapMarks} onMarkerClickAction="navigate">
        {instructor && (
          <>
            <InstructorPageCard isInstructorPage instructor={instructor} />
            {instructor.studio && instructor.isActive && (
              <StudioCard studio={instructor.studio} isDetailsAbout />
            )}
          </>
        )}
      </InstructorLayout>
    </>
  );
}

export async function getServerSideProps() {
  const mapMarks = await fetchMapMarks();

  if (!mapMarks) {
    return {
      notFound: true,
    };
  }

  return {
    props: { mapMarks },
  };
}
