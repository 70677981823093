import { get } from "@common/networking";
import { EnvConfig } from "@common/configs/EnvConfig";
import { StudiosMapMark } from "@studios/interfaces";

type Lat = string;
type Lng = string;
type id = string;

export const fetchMapMarksStudios = async (): Promise<StudiosMapMark[]> => {
  try {
    const mapMarks = get<Record<`${number}`, [Lat, Lng, id]>>(
      `${EnvConfig.MapMarksStudiosUrl}`
    );

    return mapMarks.then((studiosResponse) => {
      const mapMarks = Object.values(studiosResponse).map((mapMark) => {
        return {
          lat: Number(mapMark[0]),
          lng: Number(mapMark[1]),
          id: Number(mapMark[2]),
        };
      });

      return mapMarks;
    });
  } catch (e) {
    console.error(e);

    throw e;
  }
};
