import type { FC } from "react";
import styles from "./StudioAbout.module.scss";

export interface StudioAboutProps {
  descrition: string;
}

export const StudioAbout: FC<StudioAboutProps> = ({ descrition }) => {
  return (
    <div className={styles.root}>
      <h2 className={styles.root__header}>About studio</h2>
      <p
        className={styles.root__desc}
        dangerouslySetInnerHTML={{ __html: descrition }}
      />
    </div>
  );
};
