import { Form, FormOptions } from "@common/components";
import { useClassNameMemo, useForm } from "@common/hooks";
import {
  defaultModalContextValue,
  useModalContext,
} from "@instructors/context";
import styles from "./SearchForm.module.scss";
import { getSearchStudioFormFields } from "./getSearchStudioFormFields";
import { useStudiosContext } from "@studios/context";
import { fetchStudios } from "@studios/network";
import { useRouter } from "next/navigation";

export type SearchStudioForm = {
  className?: string;
  onMarkerClickAction: "navigate" | "filter";
};

export const SearchStudioForm: React.FC<SearchStudioForm> = ({
  className,
  onMarkerClickAction,
}) => {
  const formClassName = useClassNameMemo([styles.root, className]);
  const router = useRouter();

  const { setStudios } = useStudiosContext();
  const { setModals } = useModalContext();

  const getStudios = async (formState: Record<string, string>) => {
    const studios = await fetchStudios(formState);
    if (studios.length === 0) {
      setModals({
        ...defaultModalContextValue,
        messageModal: { isVisible: true, message: "No result!" },
      });
    }
    setStudios(studios);
  };

  const { onFieldChange, onSubmit } = useForm({
    initialState: {
      name: "",
      zip: "",
      city: "",
    },
    submit: (formState) => {
      const newFormState = {
        name: formState.name,
        address: formState.city + " " + formState.zip,
      };
      getStudios(newFormState);
      onMarkerClickAction === "navigate" && router.push("/studios");
    },
  });

  const formFields = getSearchStudioFormFields({
    labelClassName: styles.field__label,
    formLineClassName: styles.field,
    onFieldChange: (name, value) => onFieldChange(name, value),
  });

  const formOptions: FormOptions = {
    title: {
      text: "Search by Studio Name",
      className: styles.root__header,
    },
    fields: formFields,
    submitButton: {
      title: "SEARCH",
      onSubmit: () => {
        onSubmit();
      },
    },
  };

  return <Form className={formClassName} formOptions={formOptions} />;
};
