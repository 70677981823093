import { useRef, type FC } from "react";
import styles from "./StudioAvatar.module.scss";
import Image from "next/image";
import { ImagesPaths } from "@common/configs/ImageConfig";

interface StudioAvatarProps {
  imgSrc: string | null;
}

export const StudioAvatar: FC<StudioAvatarProps> = ({ imgSrc }) => {
  const imageRef = useRef<HTMLImageElement | null>(null);

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Image
          ref={imageRef}
          className={styles.root__avatar}
          // src={imgSrc ?? ImagesPaths.studioPlaceholder}
          src={ImagesPaths.studioPlaceholder}
          alt={"avatar"}
          placeholder="blur"
          sizes="100%"
          style={{ maxHeight: imageRef.current?.clientWidth }}
          blurDataURL={ImagesPaths.studioPlaceholder}
          fill={true}
        />
      </div>
    </div>
  );
};
