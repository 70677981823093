import { FC } from "react";
import { Flex } from "@common/components";
import styles from "./StudiosGrid.module.scss";
import { StudioCard } from "../StudioCard";
import { IStudio } from "@studios/interfaces";

export type StudiosGridProps = {
  studios: IStudio[];
};

export const StudiosGrid: FC<StudiosGridProps> = ({ studios }) => {
  return (
    <Flex className={styles.root}>
      {studios.map((studio, i) => (
        <StudioCard key={i} className={styles.root__card} studio={studio} />
      ))}
      <div className={styles.root__card}></div>
    </Flex>
  );
};
