import styles from "./StudioCard.module.scss";
import { Flex } from "@common/components";
import { concatClassNames } from "@common/utils";
import { StudioAvatar } from "./partials/StudioAvatar";
import { StudioCredentials } from "./partials/StudioCredentials";
import { StudioAbout } from "./partials/StudioAbout";
import { StudioContacts } from "./partials/StudioContacts";
import { useRouter } from "next/router";
import { IStudio } from "@studios/interfaces";

interface IStudioCardProps {
  studio: IStudio;
  className?: string;
  isDetailsAbout?: boolean;
  isStudioPage?: boolean;
}

export const StudioCard = ({
  studio,
  className,
  isDetailsAbout = false,
  isStudioPage = false,
}: IStudioCardProps) => {
  const router = useRouter();

  if (!studio) {
    return null;
  }

  return (
    <Flex
      className={concatClassNames([
        styles.root,
        className,
        isDetailsAbout ? styles.isDetailsAbout : "",
        !isStudioPage ? styles.isStudioPage : "",
      ])}
      direction="column"
      onClick={() =>
        !isStudioPage && studio && router.push(`studios/${studio.id}`)
      }
    >
      <Flex className={styles.info}>
        <StudioAvatar imgSrc={studio.logo} />

        <div className={styles.main}>
          <StudioCredentials name={studio.name} />
          <StudioContacts
            address={studio.address}
            phone={studio.phone}
            email={studio.email}
            website={isDetailsAbout ? studio.website : null}
          />
        </div>
      </Flex>

      {isDetailsAbout && (
        <StudioAbout
          descrition={studio?.desc ? studio.desc : "Has no description"}
        />
      )}
    </Flex>
  );
};
